import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';

export const SkipInterceptHeader = 'X-FL-Skip-Intercept';
export const SkipInterceptHeaders = new HttpHeaders({
  [SkipInterceptHeader]: '',
});

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has(SkipInterceptHeader)) {
      const headers = request.headers.delete(SkipInterceptHeader);
      request = request.clone({ withCredentials: true, headers });
    } else {
      request = request.clone({
        withCredentials: true,
        setHeaders: { 'Content-Type': 'application/ld+json' },
      });
    }

    return next.handle(request);
  }
}
